<template>
  <div class="home-container">
    <div class="has-text-centered">
      <div class="buttons-div" v-if="isEvaluate">
        <button class="button is-success"  @click="$emit('loadQuestion', 0)" v-show="showEvaluateAssignment">Start Evaluation</button>
        <button class="button is-warning" @click="showMarkAsEvaluated" v-show="showEvaluateAssignment">Mark as Evaluated</button>
        <button class="button is-warning" @click="confirmAutoEvaluateAgain" v-show="showAutoEvaluateAssignment">Auto Evaluate Again</button>
        <button class="button is-info" @click="showReOpen('In Progress')" v-show="showReopenForResubmission">Reopen for Resubmission</button>
        <button class="button is-info" @click="showReOpen('Submitted')" v-show="showReopenForReevaluation">Reopen for Reevaluation</button>
        <button class="button is-info" @click="showConfirmDiscloseResult" v-if="showDiscloseResult">Disclose Result</button>
      </div>
      <div class="buttons-div" v-if="isEvaluate">
        <button class="button" @click="reload" v-show="showAutoEvaluateAssignment">Reload</button>
      </div>
      <div class="ass-status"><span class="has-text-weight-bold">Assignment Status:</span> {{assignment.status}}</div>
      <div class="ass-status">
        <span class="has-text-weight-bold">Submission Status:</span>&nbsp;
        <span v-if="assignment.submissionStatus">{{assignment.submissionStatus}}</span>
        <span v-else>NA</span>
      </div>
      <div class="columns" v-if="!isEvaluate">
        <div class="column is-6-tablet is-offset-3-tablet ass-description has-background-white-ter white-space-pre" v-html="assignment.description"></div>
      </div>
      <div v-if="isEvaluate" class="container">
        <div class="columns">
          <div class="column is-8-tablet is-offset-2-tablet">
            <table class="table is-striped is-center is-centered is-fullwidth">
              <thead>
              <tr>
                <th></th>
                <th class="has-text-centered">Question</th>
                <th class="has-text-centered">Answered</th>
                <th class="has-text-centered">Evaluated</th>
                <th class="has-text-centered" v-if="hasMarks()">Mark</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(question, index) in assignment.questions" v-bind:key="index">
                <td>{{index+1}}</td>
                <td><a @click="$emit('loadQuestion', index)">{{question.question}}</a></td>
                <td class="has-text-centered has-text-weight-semibold" v-bind:class="{'has-text-success': isGreenAnswer(getSubmission(question).answer), 'has-text-danger': !isGreenAnswer(getSubmission(question).answer)}">{{isAnswered(getSubmission(question).answer)}}</td>
                <td class="has-text-centered has-text-weight-semibold" v-bind:class="{'has-text-success': isGreen(getSubmission(question).evaluated), 'has-text-danger': !isGreen(getSubmission(question).evaluated)}">{{isYesOrNo(getSubmission(question).evaluated)}}</td>
                <td class="has-text-centered has-text-weight-semibold" v-if="hasMarks()">{{getSubmission(question).mark}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="submission-total"  v-if="hasMarks()">Total Mark : {{submissionTotal}}</div>
      </div>
      <div v-if="isResultAnnounced && isAttend" class="container">
        <div class="submission-total" v-if="hasMarks()">Total Mark : {{assignment.totalMark}}</div>
        <div class="columns">
          <div class="column is-8-tablet is-offset-2-tablet">
            <table class="table is-striped is-center is-centered is-fullwidth">
              <thead>
              <tr>
                <th></th>
                <th class="has-text-centered">Question</th>
                <th class="has-text-centered" v-if="hasMarks()">Mark</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(question, index) in assignment.questions" v-bind:key="index">
                <td>{{index+1}}</td>
                <td><a @click="$emit('loadQuestion',index)">{{question.question}}</a></td>
                <td class="has-text-centered has-text-weight-semibold" v-if="hasMarks()">{{assignment.marks[index]}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="!isEvaluate && !isNotSubmittedAndOnHold && !isNotSubmittedBeforeCompletion" class="buttons-div">
        <button class="button is-info has-text-weight-bold" @click="$emit('loadQuestion',0)">
        <span v-if="!attendAssignment">View</span><span v-if="attendAssignment">Attend</span>&nbsp;Assignment
        </button>
        <button class="button is-warning has-text-weight-bold" @click="showRequestForReevaluation" v-if="isResultAnnounced">
          Request For Reevaluation
        </button>
      </div>
      <div v-else-if="isNotSubmittedBeforeCompletion">
        This assignment not submitted before its closing time.
      </div>
      <div v-else-if="isNotSubmittedAndOnHold">
        This assignment on hold.
      </div>
    </div>
    <div v-if="isAttend" class="has-text-centered buttons-div">
      <button class="button has-text-weight-bold" @click="saveAndExit">Exit</button>
    </div>
  </div>
</template>

<script>
import { GURU_CONSTANTS, GURU_EVENTS } from '../../../assets/javascript/constants'
import { eventBus } from '../../../assets/javascript/event-bus'
import formMixin from '../../../assets/javascript/form-mixin'

export default {
  name: 'guruAssignmentSummary',
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent', 'assignment', 'isAttend', 'isEvaluate', 'submissions'],
  mixins: [formMixin],
  mounted () {
    eventBus.$on(GURU_EVENTS.AUTO_EVALUATE_ASSIGNMENT_AGAIN, () => {
      this.autoEvaluateAgain()
    })

    eventBus.$on(GURU_EVENTS.REQUEST_FOR_REEVALUATION, () => {
      this.requestForReevaluation()
    })

    eventBus.$on(GURU_EVENTS.MARK_AS_EVALUATED, () => {
      this.markAsEvaluated()
    })

    eventBus.$on(GURU_EVENTS.DISCLOSE_RESULT, () => {
      this.discloseResult()
    })

    eventBus.$on(GURU_EVENTS.REOPEN_ASSESSMENT, (status) => {
      this.reOpen(status)
    })
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.AUTO_EVALUATE_ASSIGNMENT_AGAIN)
    eventBus.$off(GURU_EVENTS.REQUEST_FOR_REEVALUATION)
    eventBus.$off(GURU_EVENTS.MARK_AS_EVALUATED)
    eventBus.$off(GURU_EVENTS.DISCLOSE_RESULT)
    eventBus.$off(GURU_EVENTS.REOPEN_ASSESSMENT)
  },
  methods: {
    saveAndExit () {
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode)
    },
    showMarkAsEvaluated () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to mark as evaluated?',
          'yesText': 'Yes',
          'noText': 'No',
          'notifyEvent': GURU_EVENTS.MARK_AS_EVALUATED,
          'inProgressMessage': 'JDoodle is updating the status, Please wait...',
          'successMessage': 'Marked As Evaluated.',
          'failureMessage': 'Unable to submit your request. Please try again.'
        })
    },
    markAsEvaluated () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/markAsEvaluated',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId,
          studentEmail: this.$store.state.studentUnderEvaluation
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          this.$emit('updateAssessmentStatus', GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.EVALUATED)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-assignment-mark-as-evaluated',
        jdaLabel: ''
      })
    },
    showReOpen (status) {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to reopen this assignment?',
          'yesText': 'Yes',
          'noText': 'No',
          'notifyEvent': GURU_EVENTS.REOPEN_ASSESSMENT,
          refData: status,
          'inProgressMessage': 'JDoodle is updating the status, Please wait...',
          'successMessage': 'Updated.',
          'failureMessage': 'Unable to submit your request. Please try again.'
        })
    },
    reOpen (status) {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/reOpen',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId,
          studentEmail: this.$store.state.studentUnderEvaluation,
          reason: status
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          this.$emit('updateAssessmentStatus', status)
          if (status === 'In Progress') {
            window._.delay(() => {
              this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignment/' + this.$route.params.insAssignmentId)
            }, 1000)
          }
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-assignment-mark-as-evaluated',
        jdaLabel: ''
      })
    },
    showConfirmDiscloseResult () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to disclose the result?',
          'yesText': 'Yes',
          'noText': 'No',
          'notifyEvent': GURU_EVENTS.DISCLOSE_RESULT,
          'inProgressMessage': 'JDoodle is updating the status, Please wait...',
          'successMessage': 'Result Announced.',
          'failureMessage': 'Unable to submit your request. Please try again.'
        })
    },
    discloseResult () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/discloseResult',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId,
          studentEmail: this.$store.state.studentUnderEvaluation,
          reason: 'Submitted'
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          this.$emit('updateAssessmentStatus', GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.RESULT_ANNOUNCED)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-assignment-mark-as-evaluated',
        jdaLabel: ''
      })
    },
    showRequestForReevaluation () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to request for reevaluation?',
          'yesText': 'Yes',
          'noText': 'No',
          'notifyEvent': GURU_EVENTS.REQUEST_FOR_REEVALUATION,
          'inProgressMessage': 'JDoodle is submitting your request, Please wait...',
          'successMessage': 'Request Submitted.',
          'failureMessage': 'Unable to submit your request. Please try again.'
        })
    },
    requestForReevaluation () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/requestRevaluation',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
          this.$emit('updateAssessmentStatus', GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.REEVALUATION_REQUESTED)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-assignment-request-reevaluation',
        jdaLabel: ''
      })
    },
    reload () {
      this.$emit('reload')
    },
    confirmAutoEvaluateAgain () {
      eventBus.$emit(GURU_EVENTS.SHOW_IN_SIDEBAR, () => import('../../Utils/ConfirmationSideBar.vue'),
        {
          'confirmationText': 'Are you sure, do you want to submit for auto-evaluation?',
          'yesText': 'Yes',
          'noText': 'No',
          'notifyEvent': GURU_EVENTS.AUTO_EVALUATE_ASSIGNMENT_AGAIN,
          'inProgressMessage': 'JDoodle is submitting the assignment for auto evaluation, Please wait...',
          'successMessage': 'Assignment Submitted for Auto Evaluation.',
          'failureMessage': 'Unable to submit for auto evaluation. Please try again.'
        })
    },
    autoEvaluateAgain () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/assignment/autoEvaluateAgain',
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId,
          studentEmail: this.$store.state.studentUnderEvaluation
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_SUCCESS_NOTIFICATION)
        },
        failureAction: () => {
          eventBus.$emit(GURU_EVENTS.CONFIRMATION_ACTION_FAILED_NOTIFICATION)
        },
        jdaEvent: 'guru-assignment-submit-for-auto-evaluation',
        jdaLabel: ''
      })
    },
    getSubmission (question) {
      let submission = this.submissions.findIndex((o) => { return o.questionId === question.id })

      if (submission > -1) {
        return this.submissions[submission]
      }

      return {}
    },
    isYesOrNo (inp) {
      return (inp === true || inp === 'true') ? 'Yes' : 'No'
    },
    isGreen (inp) {
      return (inp === true || inp === 'true')
    },
    isAnswered (answer) {
      return (window._.isBoolean(answer) || window._.isNumber(answer) || !window._.isEmpty(answer)) ? 'Yes' : 'No'
    },
    isGreenAnswer (answer) {
      return (window._.isBoolean(answer) || window._.isNumber(answer) || !window._.isEmpty(answer))
    },
    hasMarks () {
      return this.assignment && this.assignment.resultType !== 2
    }
  },
  computed: {
    submissionTotal () {
      let total = 0
      for (let sub in this.submissions) {
        let mark = this.submissions[sub].mark
        if (window._.isNumber(mark)) {
          total += mark
        }
      }
      return total
    },
    showEvaluateAssignment () {
      let status = this.assignment.submissionStatus
      let assignmentStudentStatus = GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS
      return (status === assignmentStudentStatus.SUBMITTED || status === assignmentStudentStatus.ERROR_WHEN_AUTO_CORRECT ||
        status === assignmentStudentStatus.PARTIALLY_EVALUATED)
    },
    showAutoEvaluateAssignment () {
      let assignmentStudentStatus = GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS
      return this.assignment.autoCorrect && this.assignment.submissionStatus !== assignmentStudentStatus.YET_TO_START && this.assignment.submissionStatus !== assignmentStudentStatus.IN_PROGRESS &&
        this.assignment.submissionStatus !== assignmentStudentStatus.RESULT_ANNOUNCED && this.assignment.submissionStatus !== assignmentStudentStatus.REEVALUATION_REQUESTED && this.assignment.submissionStatus !== assignmentStudentStatus.EVALUATED
    },
    showReopenForReevaluation () {
      let assignmentStudentStatus = GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS
      return this.assignment.submissionStatus === assignmentStudentStatus.EVALUATED || this.assignment.submissionStatus === assignmentStudentStatus.RESULT_ANNOUNCED ||
      this.assignment.submissionStatus === assignmentStudentStatus.REEVALUATION_REQUESTED
    },
    showReopenForResubmission () {
      return this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.IN_PROGRESS
    },
    showDiscloseResult () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.EVALUATED
    },
    attendAssignment () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START || this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS
    },
    isResultAnnounced () {
      return this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.RESULT_ANNOUNCED
    },
    isNotSubmittedBeforeCompletion () {
      return (this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START || this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS) &&
        this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.COMPLETED
    },
    isNotSubmittedAndOnHold () {
      return (this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.YET_TO_START || this.assignment.submissionStatus === GURU_CONSTANTS.ASSIGNMENT_STUDENT_STATUS.IN_PROGRESS) &&
        this.assignment.status === GURU_CONSTANTS.ASSIGNMENT_STATUS.ON_HOLD
    }
  }
}
</script>

<style scoped lang="scss">
    .ass-status {
      padding: 5px;
    }

    .ass-description {
      border: 1px solid #ddd;
      border-radius: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: left;
    }

    .buttons-div {
      margin:1em;
       button {
         margin-right: 10px;
         font-weight: 600;
       }
    }

  .submission-total {
    font-size:1.6em;
    font-weight: bold;
    text-align: center;
  }
</style>
